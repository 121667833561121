import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import type { ComputedRef } from 'vue';
import { useDev } from '@/utils/composables/useDev';
import { useAuthStore } from '@/stores/auth';

export const useLiveChat = (): {
	isPrimeChatEnabled: ComputedRef<boolean>;
} => {
	const userStore = useUserStore();
	const { userId, isPrimeChatAvailableForUser, isSupportChatAvailable } =
		storeToRefs(userStore);
	const { adminId, isAuthorized, isSuperAdmin } = storeToRefs(useAuthStore());
	const { isDev } = useDev();

	const isPrimeChatEnabled = computed(() => {
		if (!isAuthorized.value) return false; // User is not authorized
		if (!isSupportChatAvailable.value) return false; // Any chat is disabled

		if (!userId.value || (adminId.value && !isSuperAdmin.value)) return false;

		return isPrimeChatAvailableForUser.value || isDev.value;
	});

	return {
		isPrimeChatEnabled,
	};
};
