import { computed } from 'vue';
import {
	SURVEYS,
	LEADERBOARD,
	ACCOUNT,
	OFFERWALLS_TITLE,
	MORE,
	GAMES,
	REF_PAGE_TITLE,
} from '@/locales/constants';
import { useI18n } from 'vue-i18n';
import type { NavItem } from '@/types';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import {
	PiHcSurveys,
	PiHcLeaderboardfilled,
	PiHcOffersfilled,
	PiHcAccount,
	PiDotsThreeCircle,
	PiGift,
	PiGameControllerDuotone,
} from '@primeinsightsgroupllc/prime-icons';
import { USER_ROUTE_NAME } from '@/constants/routes';
import { useAppStore } from '@/stores/app';
import { useMobileApp } from '@/utils/composables/useMobileApp';

export const useNavItems = () => {
	const { t } = useI18n();
	const { isLeaderboardActive, isOfferwallsAvailable, isUserAdjoeEnabled } =
		storeToRefs(useUserStore());
	const { isAdjoeEnabled } = storeToRefs(useAppStore());
	const { isTablet, isAndroid, isMobileApp } = useMobileApp();

	const moreItems = computed<Array<NavItem>>(() => [
		{
			icon: PiHcLeaderboardfilled,
			label: t(LEADERBOARD),
			to: USER_ROUTE_NAME.LEADERBOARD,
			isActive: Boolean(isLeaderboardActive.value),
			id: USER_ROUTE_NAME.LEADERBOARD,
			placed: ['mobile'],
		},
		{
			icon: PiHcAccount,
			label: t(ACCOUNT),
			to: USER_ROUTE_NAME.PROFILE,
			isActive: true,
			id: USER_ROUTE_NAME.PROFILE,
			placed: ['mobile'],
		},
		{
			icon: PiGift,
			label: t(REF_PAGE_TITLE),
			to: USER_ROUTE_NAME.REFERRALS,
			isActive: true,
			id: USER_ROUTE_NAME.REFERRALS,
			placed: ['mobile'],
		},
	]);

	const items = computed<Array<NavItem>>(() => [
		{
			icon: PiHcSurveys,
			label: t(SURVEYS),
			to: USER_ROUTE_NAME.SURVEYS,
			isActive: true,
			id: USER_ROUTE_NAME.SURVEYS,
			placed: ['desktop', 'mobile'],
		},
		{
			icon: PiGameControllerDuotone,
			label: t(GAMES),
			to: USER_ROUTE_NAME.GAMES,
			isActive: Boolean(
				isAdjoeEnabled.value &&
					isUserAdjoeEnabled.value &&
					isMobileApp &&
					(!isTablet() || (isTablet() && !isAndroid.value))
			),
			id: USER_ROUTE_NAME.GAMES,
			placed: ['mobile'],
		},
		{
			icon: PiHcLeaderboardfilled,
			label: t(LEADERBOARD),
			to: USER_ROUTE_NAME.LEADERBOARD,
			isActive: Boolean(isLeaderboardActive.value),
			id: USER_ROUTE_NAME.LEADERBOARD,
			placed: ['desktop'],
		},
		{
			icon: PiHcOffersfilled,
			label: t(OFFERWALLS_TITLE),
			to: USER_ROUTE_NAME.OFFERWALLS_PARTNERS,
			isActive: Boolean(isOfferwallsAvailable.value),
			id: USER_ROUTE_NAME.OFFERWALLS_PARTNERS,
			placed: ['desktop', 'mobile'],
		},
		{
			icon: PiHcAccount,
			label: t(ACCOUNT),
			to: USER_ROUTE_NAME.PROFILE,
			isActive: true,
			id: USER_ROUTE_NAME.PROFILE,
			placed: ['desktop'],
		},
		{
			icon: PiDotsThreeCircle,
			label: t(MORE),
			isActive: true,
			id: 'more',
			placed: ['mobile'],
			children: moreItems.value as NavItem[],
		},
	]);

	const filterItems = (
		items: NavItem[],
		type: 'mobile' | 'desktop'
	): NavItem[] => {
		return items.filter((item) => item.placed.includes(type) && item.isActive);
	};

	const desktopItems = computed(() => filterItems(items.value, 'desktop'));
	const mobileItems = computed(() => filterItems(items.value, 'mobile'));

	return {
		desktopItems,
		mobileItems,
		items,
	};
};
