<script setup lang="ts">
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import OverlayPanel from 'primevue/overlaypanel';
import { CLICK_TO_OPEN_STREAK, DAY_STREAK } from '@/locales/constants';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import money_coin_yellow from '@/assets/money-coin-yellow.svg';
import { useScreenSize } from '@/utils/composables/useScreenSize';

const { streaks } = storeToRefs(useUserStore());

const { isMobile } = useScreenSize();
const showOverlay = ref(false);

const streakInfo = ref<InstanceType<typeof OverlayPanel> | null>(null);

const showStreakInfo = (event: Event) => {
	streakInfo.value?.show(event);
};

const hideStreakInfo = () => {
	streakInfo.value?.hide();
};
</script>

<template>
	<div
		class="app-header-points streak-info-container"
		:class="{ active: showOverlay }"
		@mouseover="showStreakInfo"
		@mouseleave="hideStreakInfo"
	>
		<img :src="money_coin_yellow" alt="" />
		<PrimeText :size="isMobile ? 'base' : 'lg'" weight="600" color="inherit">
			{{ streaks }}
		</PrimeText>
		<PrimeText size="sm" weight="400">
			{{ $t(DAY_STREAK) }}
		</PrimeText>
		<OverlayPanel
			id="streak-info"
			ref="streakInfo"
			append-to=".streak-info-container"
			@show="showOverlay = true"
			@hide="showOverlay = false"
		>
			<PrimeText weight="400" color="white" size="base">
				{{ $t(CLICK_TO_OPEN_STREAK) }}
			</PrimeText>
		</OverlayPanel>
	</div>
</template>

<style lang="scss">
@import '@/styles/mixins.scss';

.app-header {
	&-points {
		user-select: none;
		display: flex;
		align-items: center;
		color: white;
		gap: 0.25rem;
		border-radius: 8px;
		border: 1px solid transparent;
		transition: all 0.1s linear;
		position: relative;
		padding: 0;
		margin-right: 0.5rem;

		img {
			margin-right: 0;

			@include breakpoint(mobileonly) {
				margin-right: 0.625rem;
			}
		}

		@include breakpoint(mobileonly) {
			margin-right: 1.3125rem;
			padding: 6px;
		}

		&:hover,
		&.active {
			border: 1px solid #686868;
		}
	}
}

.user-balance {
	z-index: 1;

	&--overlay {
		.user-balance-info {
			position: relative;
			z-index: 13;
			border-color: transparent;
			background-color: var(--white);
			border-radius: 0.1875rem;
		}
	}
}

.user-balance-info {
	display: flex;
	align-items: center;
	padding: 0.375rem;
	border: 0;
	border-radius: 0.25rem;
	background: transparent;
	font-weight: 600;
	cursor: pointer;
	color: var(--text-default);

	& svg {
		flex-shrink: 0;
	}

	&:hover {
		background-color: var(--grey-100);
	}
}

.user-balance-amount {
	margin-left: 0.1875rem;
	display: flex;
	gap: 0.25rem;
	flex-wrap: wrap;
	max-height: 1.5rem;
	overflow: hidden;
	flex: 1 1 auto;
	justify-content: flex-end;

	& .points,
	& .balance {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

#streak-info {
	color: white;
	left: calc(50% + 0.5rem) !important;
	top: 1.375rem !important;
	transform: translate(-50%, 50%) !important;
	width: max-content;
	.p-overlaypanel-content {
		padding: 0.75rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 0.875rem;
	}
}
</style>
