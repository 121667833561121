import { watch } from 'vue';
import {
	LEADERBOARD_MODAL,
	LEADERBOARD_WEEKLY_MODAL,
	MOBILE_APP_HERE_MODAL,
	OFFERWALLS_REWARD_NOTIFICATION_MODAL,
	OFFERWALLS_ONETIME_NOTIFICATION_MODAL,
	OFFERWALLS_WELL_DONE_MODAL,
} from '@/constants/modals';
import { storeToRefs } from 'pinia';
import { useMessagesStore } from '@/stores/messages';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { useAuthStore } from '@/stores/auth';
import { MessageChannels, LeaderboardTypes } from '@/enums';
import type { Notification } from '@/types';

export const useAppNotificationListener = (): {
	showLeaderboardModal: (options: Record<string, any>) => void;
} => {
	const modalStorage = useModalStorage();
	const { notificationsList } = storeToRefs(useMessagesStore());
	const { userAuthToken } = storeToRefs(useAuthStore());

	const showLeaderboardModal = (options: Record<string, any>): void => {
		modalStorage.pushModal({
			name: LEADERBOARD_MODAL,
			options,
		});
	};

	const showNotificationsModal = ({ data, id }: Notification): void => {
		const requiredOptions = { id, channel: MessageChannels.NOTIFICATION };
		switch (data.event_name) {
			case 'offerwall_transaction_delayed':
				modalStorage.pushModal({
					name: OFFERWALLS_WELL_DONE_MODAL,
					options: { ...requiredOptions },
					data,
				});
				break;
			case 'onetime_switched_to_weekly_leaderboards':
				modalStorage.pushModal({
					name: LEADERBOARD_WEEKLY_MODAL,
					options: { ...requiredOptions },
				});
				break;
			case 'user-won-leaderboard-prize':
				showLeaderboardModal({
					type: LeaderboardTypes.SCORED,
					rank: data.rank,
					coins: data.coins,
					...requiredOptions,
				});
				break;
			case 'onetime_mobile_app_is_here_001':
				modalStorage.pushModal({
					name: MOBILE_APP_HERE_MODAL,
					options: {
						...requiredOptions,
					},
				});
				break;
			case 'ten-surveys-completed':
				showLeaderboardModal({
					type: LeaderboardTypes.UNLOCKED,
					...requiredOptions,
				});
				break;
			case 'onetime_offerwall_ff_enabled':
				modalStorage.pushModal({
					name: OFFERWALLS_ONETIME_NOTIFICATION_MODAL,
					options: {
						...requiredOptions,
					},
				});
				break;
			case 'offerwall_transaction_completed':
				modalStorage.pushModal({
					name: OFFERWALLS_REWARD_NOTIFICATION_MODAL,
					options: {
						...requiredOptions,
						offer_name: data.offer_name,
						coins_earned: data.coins_earned,
					},
				});
				break;
			default:
				break;
		}
	};

	watch(
		[userAuthToken, notificationsList],
		async (newValue) => {
			const [token, notifications] = newValue;
			if (token && notifications?.length) {
				notifications.forEach((notification) => {
					showNotificationsModal(notification);
				});
			}
		},
		{ immediate: true }
	);

	return {
		showLeaderboardModal,
	};
};
