<script setup lang="ts">
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { useUserStore } from '@/stores/user';
import {
	PrimeButton,
	PrimeDivider,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { CLAIM_REWARD, POINTS } from '@/locales/constants';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { DAILY_STREAK_MODAL, REWARDS_MODAL } from '@/constants/modals';
import UserBalance from '@/components/common/header/UserBalance.vue';
import ProgressBarContainer from '@/components/common/header/ProgressBarContainer.vue';
import UserStreak from '@/components/common/header/UserStreak.vue';

const { isAuthorized, isTopNavVisible } = storeToRefs(useAuthStore());
const {
	levelReward,
	coinsEarned,
	coinsTarget,
} = storeToRefs(useUserStore());
const modalStorage = useModalStorage();

const headerEl = ref<HTMLElement | null>(null);

const progress = computed(
	() => (Number(coinsEarned.value) / Number(coinsTarget.value)) * 100
);

const openDayStreakModal = () => {
	modalStorage.pushModal({ name: DAILY_STREAK_MODAL });
};

const openRewardsModal = () => {
	modalStorage.pushModal({
		name: REWARDS_MODAL,
		options: { useDefaultClose: false, position: 'bottom' },
	});
};
</script>

<template>
	<header
		ref="headerEl"
		class="app-header"
		:class="{
			'authorized-view': isAuthorized,
			'hide-on-mobile': !isTopNavVisible,
		}"
	>
		<div class="app-header__wrapper">
			<ProgressBarContainer :progress="progress">
				<PrimeText weight="400" color="white" size="base">
					{{ levelReward }} {{ $t(POINTS) }}
				</PrimeText>
			</ProgressBarContainer>
			<div class="app-header-rewards">
				<UserBalance />
				<UserStreak @click="openDayStreakModal" />
				<PrimeButton
					:label="$t(CLAIM_REWARD)"
					type="fill"
					@click="openRewardsModal"
				/>
			</div>
		</div>
		<PrimeDivider color="grey-100" />
	</header>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.app-header {
	padding-top: env(safe-area-inset-top);
	padding-left: 16.875rem;
	width: 100%;
	max-width: 100%;
	display: none;

	@include breakpoint(tablet) {
		display: block;
	}

	&-stats {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0.625rem;
	}

	&-rewards {
		display: flex;
		cursor: pointer;
	}

	&-points {
		user-select: none;
		display: flex;
		align-items: center;
		color: white;
		gap: 0.25rem;
		border-radius: 8px;
		border: 1px solid transparent;
		transition: all 0.1s linear;
		position: relative;
		padding: 0;
		margin-right: 0.5rem;

		img {
			margin-right: 0;

			@include breakpoint(mobileonly) {
				margin-right: 0.625rem;
			}
		}

		@include breakpoint(mobileonly) {
			margin-right: 1.3125rem;
			padding: 6px;
		}

		&:hover,
		&.active {
			border: 1px solid #686868;
		}
	}

	&.hide-on-mobile {
		visibility: hidden;
		height: 0;

		@include breakpoint(mobile) {
			visibility: unset;
			height: auto;
		}
	}

	&.authorized-view {
		position: fixed;
		background: var(--white);
		z-index: 11;

		.app-header__col {
			&--back {
				@include breakpoint(tablet) {
					min-width: 25rem;
				}
			}

			&--progress {
				display: none;

				@include breakpoint(tablet) {
					display: flex;
					flex: 1 1 9.375rem;
				}
			}

			&--services {
				flex: 1 0 auto;

				@include breakpoint(tablet) {
					flex: 0 0 auto;
				}
			}
		}
	}

	&__wrapper {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		height: 5rem;

		@include breakpoint(tablet) {
			padding: 1.3125rem 2.75rem;
		}
	}

	&__col {
		padding-top: 0.875rem;
		padding-bottom: 0.875rem;
		min-width: 2.25rem;

		@include breakpoint(tablet) {
			padding-top: 0.75rem;
			padding-bottom: 0.75rem;
		}

		&--desktop-menu {
			flex: 1 0 auto;
			padding-top: 0;
			padding-bottom: 0;
		}

		&--services {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: flex-end;
		}

		:deep(.p-divider--vertical) {
			height: auto;
			align-self: stretch;
		}
	}

	&__balance {
		display: flex;
		gap: 0.5rem;
		align-items: center;
		margin-inline-start: 0;
		margin-inline-end: auto;

		@include breakpoint(tablet) {
			margin-inline-start: auto;
			margin-inline-end: 0;
		}
	}

	&__service {
		display: flex;
	}
}

.header-buttons {
	display: flex;
	gap: 0.625rem;
}

.login-button,
.signup-button {
	display: none;
	width: 8.75rem;

	@include breakpoint(mobile) {
		display: block;
	}
}
</style>
