<script setup lang="ts">
import tree from '@/assets/tree.svg';
import {
	CONGRATS,
	LEADERBOARD_UNLOCKED_MESSAGE,
	TAKE_SURVEYS,
	UNLOCK_OFFERS,
	UNLOCK_OFFERS_LEFT,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import {
	PrimeButton,
	PrimeProgressbar,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { computed, watch } from 'vue';
import { useModalStorage } from '@/utils/composables/useModalStorage';

const router = useRouter();
const userStore = useUserStore();
const { offerwallsMeta } = storeToRefs(useUserStore());

const progress = computed(
	() => (offerwallsMeta.value?.progress! / offerwallsMeta.value?.target!) * 100
);
const surveysLeft = computed(
	() => offerwallsMeta.value?.target! - offerwallsMeta.value?.progress!
);

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const goToSurveys = () => {
	router.push({ name: 'surveys' });
	emit('close-modal');
};

watch(surveysLeft, async (v) => {
	if (!v) {
		await userStore.fetchFeatures();
		await useModalStorage().shiftModal();
	}
});
</script>

<template>
	<ModalCommonContent id="offerwalls-modal">
		<template #header>
			<template>{{ $t(CONGRATS) }}</template>
		</template>
		<template #icon>
			<img :src="tree" alt="" />
		</template>
		<template #title>
			<div class="message-locked">
				<PrimeText tag="div" weight="600" size="lg" color="inherit">
					{{ $t(UNLOCK_OFFERS) }}
				</PrimeText>
				<PrimeText tag="div" size="sm" weight="400" color="grey-700">
					{{ $t(UNLOCK_OFFERS_LEFT, { surveys_left: surveysLeft }) }}
				</PrimeText>
			</div>
		</template>
		<template #message>
			<template>
				{{ $t(LEADERBOARD_UNLOCKED_MESSAGE) }}
			</template>

			<div class="message-locked">
				<div class="message-locked-progress">
					<PrimeProgressbar :value="progress" />
					<PrimeText size="sm" weight="400" color="inherit">
						{{ offerwallsMeta?.progress }}
					</PrimeText>
					<PrimeText size="sm" weight="400" color="grey-700">
						/ {{ offerwallsMeta?.target }}
					</PrimeText>
				</div>
			</div>
		</template>
		<template #actions>
			<PrimeButton full-width :label="$t(TAKE_SURVEYS)" @click="goToSurveys" />
		</template>
	</ModalCommonContent>
</template>

<style lang="scss">
#offerwalls-modal {
	text-align: left;
	align-items: flex-start;
	.content-title {
		& > div > span > span {
			display: inline-flex;
			align-items: center;
			width: 2rem;
			height: 2rem;
		}
	}

	.content-message {
		color: var(--grey-700);
		max-width: 100%;
		width: 100%;
	}

	.message-locked {
		color: white;
		margin-bottom: 1.5rem;

		&-prize {
			margin-top: 1.5rem;
		}

		.p-progress {
			margin-bottom: 0.25rem;
			border-radius: 3.125rem;
			background: var(--p-grey-300);
		}

		.p-progress-bar {
			margin-bottom: 1.5rem;
			border-radius: 3.125rem;
			background: linear-gradient(
				270deg,
				#ff6b34 0%,
				rgba(255, 107, 52, 0) 100%
			) !important;
		}
	}

	.content-icon {
		width: 180px;
		height: 180px;
		margin-left: auto;
		margin-right: auto;
	}

	.content-actions {
		margin-top: 1.375rem;

		.content-actions-button {
			margin-top: 1rem;
		}
	}
}
</style>
