<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import OverlayPanel from 'primevue/overlaypanel';
import { BALANCE, POINTS } from '@/locales/constants';
import { localizePoints, transformCurrency } from '@/utils/helpers';
import { useI18n } from 'vue-i18n';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import { useElementBounding } from '@vueuse/core';
import money_coin_green from '@/assets/money-coin-green.svg';
import { useMobileApp } from '@/utils/composables/useMobileApp';

const { isMobile } = useScreenSize();
const { isWeb } = useMobileApp();
const { t } = useI18n();
const { collectedCoins, currencyBalance, currency } =
	storeToRefs(useUserStore());

const showOverlay = ref(false);

const userBalanceInfo = ref<InstanceType<typeof OverlayPanel> | null>(null);

const showUserBalanceInfo = (event: Event) => {
	userBalanceInfo.value?.show(event);
};

const hideUserBalanceInfo = () => {
	userBalanceInfo.value?.hide();
};

const balanceText = computed(() => {
	return `${collectedCoins.value} ${t(POINTS)} ≈ ${transformCurrency(
		+currencyBalance.value!,
		currency.value,
		2
	)}`;
});

const tooltipTargetEl = ref<HTMLElement | null>(null);
const MAX_EL_HEIGHT = 24;
const pointsEl = ref(null);
const { top } = useElementBounding(pointsEl);

const displayValue = ref('flex');

watch(top, (newVal) => {
	if (newVal > MAX_EL_HEIGHT) {
		displayValue.value = 'none';
	}
});

onMounted(() => {
	tooltipTargetEl.value = document.querySelector(
		isWeb.value ? '.app-header-points' : '.profile-widget-rewards'
	);
});
</script>

<template>
	<div
		class="app-header-points"
		:class="{ active: showOverlay }"
		@click="showUserBalanceInfo"
		@mouseover="showUserBalanceInfo"
		@mouseleave="hideUserBalanceInfo"
	>
		<img :src="money_coin_green" alt="" />
		<PrimeText :size="isMobile ? 'base' : 'lg'" weight="600" color="inherit">
			{{ localizePoints(collectedCoins) }}
		</PrimeText>
		<PrimeText size="sm" weight="400">
			{{ $t(POINTS) }}
		</PrimeText>
		<OverlayPanel
			v-if="tooltipTargetEl"
			id="balance-info"
			ref="userBalanceInfo"
			:append-to="tooltipTargetEl"
			@show="showOverlay = true"
			@hide="showOverlay = false"
		>
			<PrimeText weight="400" size="xs">{{ $t(BALANCE) }}</PrimeText>
			<PrimeText weight="400" color="white" size="base">
				{{ balanceText }}
			</PrimeText>
		</OverlayPanel>
	</div>
</template>

<style lang="scss">
@import '@/styles/mixins.scss';

.app-header {
	&-points {
		user-select: none;
		display: flex;
		align-items: center;
		color: white;
		gap: 0.25rem;
		border-radius: 8px;
		border: 1px solid transparent;
		transition: all 0.1s linear;
		position: relative;
		padding: 0;
		margin-right: 0.5rem;

		img {
			margin-right: 0;

			@include breakpoint(mobileonly) {
				margin-right: 0.625rem;
			}
		}

		@include breakpoint(mobileonly) {
			margin-right: 1.3125rem;
			padding: 6px;
		}

		&:hover,
		&.active {
			border: 1px solid #686868;
		}
	}
}

.user-balance {
	z-index: 1;

	&--overlay {
		.user-balance-info {
			position: relative;
			z-index: 13;
			border-color: transparent;
			background-color: var(--white);
			border-radius: 0.1875rem;
		}
	}
}

.user-balance-info {
	display: flex;
	align-items: center;
	padding: 0.375rem;
	border: 0;
	border-radius: 0.25rem;
	background: transparent;
	font-weight: 600;
	cursor: pointer;
	color: var(--text-default);

	& svg {
		flex-shrink: 0;
	}

	&:hover {
		background-color: var(--grey-100);
	}
}

.user-balance-amount {
	margin-left: 0.1875rem;
	display: flex;
	gap: 0.25rem;
	flex-wrap: wrap;
	max-height: 1.5rem;
	overflow: hidden;
	flex: 1 1 auto;
	justify-content: flex-end;

	& .points,
	& .balance {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	& .points {
		display: v-bind(displayValue);
	}
}

#balance-info {
	color: white;
	width: max-content;
	border: 1px solid #686868;

	.p-overlaypanel-content {
		padding: 0.75rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 0.875rem;
	}

	&::after {
		border-bottom-color: #686868;
	}

	@include breakpoint(laptop) {
		border: none;
		margin-right: 1.3125rem;
		padding: 6px;
		left: calc(50% + 0.5rem) !important;
		top: 0.875rem !important;
		transform: translate(-50%, 50%) !important;

		&::after {
			border-bottom-color: #151515;
		}
	}
}
</style>
